import { clsx } from "clsx";

import { useFlags } from "Services/FeatureFlagService";

import { TerminalTaskActions } from "../../TaskOutcomes/TerminalTaskActions/TerminalTaskActions";
import { BUTTONS_MESSAGES } from "../CallBar.consts";
import { CallBarButton } from "./CallBarButton";

import type { RVTask } from "@regal-voice/shared-types";

import styles from "../CallBar.module.scss";

export type IncomingCallBarProps = {
    activeTask?: RVTask;
    acceptingCall: boolean;
    acceptActiveTask: () => void;
};

export function IncomingCallBarButtons({
    activeTask,
    acceptActiveTask,
    acceptingCall,
}: IncomingCallBarProps): JSX.Element {
    const { enableAgentToReassignTask, customCancelTaskEnabled } = useFlags();
    const hasTerminalAction = customCancelTaskEnabled || enableAgentToReassignTask;
    return (
        <ul className={clsx(styles.buttonsList, "rv-call-bar-button-list")}>
            <li key="accept">
                <CallBarButton
                    id="accept"
                    active={false}
                    action={acceptActiveTask}
                    icon="accept"
                    messages={BUTTONS_MESSAGES.accept}
                    className={styles.accept}
                    loading={acceptingCall}
                />
            </li>
            <li key="reject">
                {hasTerminalAction ? (
                    <TerminalTaskActions task={activeTask} asPopup renderTargetClass="reject" />
                ) : (
                    <></>
                )}
            </li>
        </ul>
    );
}
