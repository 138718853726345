import { useEffect, useRef } from "react";

import { notification } from "antd";

import { RVButton } from "Components/elements/RVButton/RVButton";
import { checkActiveVersion } from "Services/VersionsService";

export const __VERSION_CHECK_INTERVAL_MS = 5 * 60 * 1000;

export function VersionChecker(): JSX.Element {
    // Must use useRef to track state so that useEffect callback has access to it
    const versionsNotifiedAbout = useRef(new Set());
    const notificationIsShowing = useRef(false);
    // meaningless change to trigger an app rebuild counter: 3

    useEffect(() => {
        const interval = setInterval(async () => {
            const { isActive, versionOnServer } = await checkActiveVersion();
            if (versionOnServer) {
                if (
                    !isActive &&
                    !versionsNotifiedAbout.current.has(versionOnServer) &&
                    !notificationIsShowing.current
                ) {
                    versionsNotifiedAbout.current.add(versionOnServer);
                    notificationIsShowing.current = true;

                    notification.warning({
                        message: "Update Available",
                        description:
                            "A new and better version of the app is available. Reload the page to get all the changes (when you are not on a live call).",
                        duration: 0,
                        placement: "topRight",
                        closeIcon: <></>,
                        btn: (
                            <RVButton type="primary" onClick={() => window.location.reload()}>
                                Reload
                            </RVButton>
                        ),
                        top: 80,
                    });
                }
            }
        }, __VERSION_CHECK_INTERVAL_MS);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return <></>;
}
